export default {
  path: "/timbre",
  meta: {
    title: "音色管理",
    icon: "ri:voiceprint-fill"
  },
  children: [
    {
      path: "/timbre/system",
      name: "Timbre",
      component: () => import("@/views/timbre/index.vue"),
      meta: {
        title: "系统音色"
      }
    },
    {
      path: "/timbre/custom",
      name: "CustomTimbre",
      component: () => import("@/views/customTimbre/index.vue"),
      meta: {
        title: "自定义音色"
      }
    },
    {
      path: "/timbre/audit",
      name: "TimbreAudit",
      component: () => import("@/views/timbreAudit/index.vue"),
      meta: {
        title: "审核"
      }
    }
  ]
} as RouteConfigsTable;
