export default {
  path: "/role",
  redirect: "/role/index",
  meta: {
    title: "角色管理",
    icon: "ri:contacts-fill",
    rank: 2
  },
  children: [
    {
      path: "/role/index",
      name: "Role",
      component: () => import("@/views/role/index.vue"),
      meta: {
        title: "角色管理"
      }
    }
  ]
} as RouteConfigsTable;
