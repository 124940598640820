export default {
  path: "/system",
  redirect: "/system/index",
  meta: {
    title: "系统设置",
    icon: "ri:settings-5-fill",
    rank: 99
  },
  children: [
    {
      path: "/system/index",
      name: "System",
      component: () => import("@/views/system/index.vue"),
      meta: {
        title: "系统设置"
      }
    }
  ]
} as RouteConfigsTable;
