export default {
  path: "/code",
  redirect: "/code/index",
  meta: {
    title: "兑换码",
    icon: "ri:exchange-cny-fill",
    rank: 99
  },
  children: [
    {
      path: "/code/index",
      name: "Code",
      component: () => import("@/views/code/index.vue"),
      meta: {
        title: "兑换码"
      }
    }
  ]
} as RouteConfigsTable;
